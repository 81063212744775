import { Outlet } from "react-router-dom";
import BottomNavbar from "./BottomNavbar";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = () => {

    return (
        <div>
            <Navbar />
            <div className="w-full pt-[60px] pb-[20px]">
                <Outlet />
                <Footer />
            </div>
            <BottomNavbar />
        </div>
    )
}

export default Layout