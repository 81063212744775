import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { useFetch } from "../hooks/useFetch";
import Cart from "../components/common/Cart";

const MyPage = () => {

    const navigate = useNavigate()

    const { userData } = useSelector(state => ({
        userData: state.auth.userData
    }));

    // useEffect(() => {
    //     if (!userData) {
    //         return;
    //     }
    // }, [userData]);

    if (!userData) {
        return null;
    }

    const { id, name, nickname } = userData;

    const Profile = () => { 
        return (
            <div className="flex flex-row justify-between h-56 mb-32 bg-zinc-100 border border-zinc-200 rounded"
                style={{ boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)"}}>
                
                <div className="flex flex-row my-auto w-1/2 ">
                    <div className="flex flex-col ml-12 my-auto ">
                        <div className="font-bold text-xl mb-3 font-nanum">
                            {`${name}(${nickname})`}
                        </div>
                        <div className="text-sm mb-3 font-raleway">
                            {`${id}`}
                        </div>
                        <div className="text-sm font-nanum hover:cursor-pointer" onClick={() => {navigate("/edit")}}>
                            계정정보수정
                        </div>
                    </div>
                </div>

                
                <div className="flex flex-row w-1/2 justify-end mt-14 mx-auto font-bold text-lg font-raleway">

                    {/* <div className="flex flex-col w-1/3">
                        <div className="flex flex-row">
                            <span className="my-auto">Point</span>
                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.7834 25.7389L16.7834 13.8111H20.2369C21.8274 13.8111 23.1168 15.1005 23.1168 16.691V16.691C23.1168 18.2815 21.8274 19.5708 20.2369 19.5708H16.7834" 
                                stroke="black"></path>
                                <path d="M5.06665 19.9843C5.65768 27.0734 11.6594 32.6444 18.9761 32.6444C26.6845 32.6444 32.9333 26.461 32.9333 18.8333C32.9333 11.2057 26.6845 5.02222 18.9761 5.02222C11.6594 5.02222 5.65768 10.5932 5.06665 17.6824" 
                                stroke="black"></path>
                            </svg>
                        </div>

                        <div className="mt-3 ml-1 font-nanum">
                           3,000
                        </div>
                    </div> */}
                        
                    <div className="flex flex-col w-1/3">
                        <div className="flex flex-row">
                            <span className="my-auto hover:cursor-pointer" onClick={() => navigate("/coupon")}>
                                Coupon
                            </span>
                            {/* <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.06665 28.8778H4.56665V29.3778H5.06665V28.8778ZM32.9333 28.8778V29.3778H33.4333V28.8778H32.9333ZM32.9333 21.3444H33.4333V20.8444H32.9333V21.3444ZM32.9333 16.3222V16.8222H33.4333V16.3222H32.9333ZM32.9333 8.78888L33.4333 8.78888V8.28888H32.9333V8.78888ZM5.06665 8.78888V8.28888H4.56665V8.78888H5.06665ZM5.06665 16.3222H4.56665V16.8222H5.06665V16.3222ZM5.06665 21.3444V20.8444H4.56665V21.3444H5.06665ZM25.3333 28.3778H5.06665V29.3778H25.3333V28.3778ZM32.9333 28.3778H25.3333V29.3778H32.9333V28.3778ZM32.4333 21.3444L32.4333 28.8778H33.4333L33.4333 21.3444H32.4333ZM32.9333 20.8444C31.8062 20.8444 30.9 19.9399 30.9 18.8333H29.9C29.9 20.5005 31.2622 21.8444 32.9333 21.8444V20.8444ZM30.9 18.8333C30.9 17.7268 31.8062 16.8222 32.9333 16.8222V15.8222C31.2622 15.8222 29.9 17.1662 29.9 18.8333H30.9ZM32.4333 8.78888L32.4333 16.3222H33.4333L33.4333 8.78888L32.4333 8.78888ZM25.3333 9.28888H32.9333V8.28888H25.3333V9.28888ZM5.06665 9.28888H25.3333V8.28888H5.06665V9.28888ZM5.56665 16.3222V8.78888H4.56665V16.3222H5.56665ZM8.09998 18.8333C8.09998 17.1662 6.73775 15.8222 5.06665 15.8222V16.8222C6.19379 16.8222 7.09998 17.7268 7.09998 18.8333H8.09998ZM5.06665 21.8444C6.73775 21.8444 8.09998 20.5005 8.09998 18.8333H7.09998C7.09998 19.9399 6.19379 20.8444 5.06665 20.8444V21.8444ZM5.56665 28.8778V21.3444H4.56665V28.8778H5.56665ZM24.8333 8.78888V11.3H25.8333V8.78888H24.8333ZM25.8333 28.8778V26.3667H24.8333V28.8778H25.8333Z" 
                                fill="black"></path>
                                <path d="M19.6333 15.0667H17.7C15.6197 15.0667 13.9333 16.753 13.9333 18.8333V18.8333C13.9333 20.9136 15.6197 22.6 17.7 22.6H19.6333" 
                                stroke="black"></path>
                                <path d="M25.3333 13.8111L25.3333 17.5778" stroke="black"></path>
                                <path d="M25.3333 20.0889L25.3333 23.8555" stroke="black"></path>
                            </svg> */}
                        </div>

                        <div className="mt-3 ml-1 font-nanum">
                           3
                        </div>
                    </div>
                </div>
                
            </div>
           
        )
    }

    const OrderStatus = () => {

        const category = [
            {
                contents: '입금/결제',
                id: 'deposit',
                detail: ["상품정보", "주문일자", "주문금액"]
            },
            {
                contents: '배송중',
                id: 'in_transit',
                detail: ["상품정보", "주문일자", "주문금액"]
            },
            {
                contents: "배송완료",
                id: "delivered",
                detail: ["상품정보", "주문일자", "주문금액"]
            },
            {
                contents: "구매확정",
                id: "purchase_confirmed",
                detail: ["상품정보", "주문일자", "주문금액"]
            },
            {
                contents: "교환",
                id: "exchange",
                detail: ["상품정보", "주문일자", "주문금액"]
            },
            {
                contents: "교환완료",
                id: "exchange_completed",
                detail: ["상품정보", "주문일자", "주문금액"]
            },
            {
                contents: "환불",
                id: "refund",
                detail: ["상품정보", "주문일자", "주문금액"]
            },
            {
                contents: "환불완료",
                id: "refund_completed",
                detail: ["상품정보", "주문일자", "주문금액"]
            },
        ]
        const { api } = useFetch()
        const orderStatusRef = useRef(null);
        const [selected, setSelected] = useState("deposit")
        const [orderData, setOrderData] = useState({})
        const [isOpen, setIsOpen] = useState(true)

        // const stateCategories = orderData.reduce((acc, item) => {
        //     const { state, data } = item;
        //     if (!acc[state]) {
        //       acc[state] = [];
        //     }
        //     acc[state].push(data);
        //     return acc;
        // }, {});

        useEffect(() => {
            const hash = window.location.hash.substring(1);
            if (hash === 'orderstatus' && orderStatusRef.current) {
                orderStatusRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, []);

        useEffect(() => {
            (async () => {
                try {
                    const { data, error } = await api({
                        action: "getOrderInfo"
                    })

                    if (!error) {
                        console.log(data)
                        setOrderData(data)
                    } else {
                        console.error(error)
                    }
                } catch (err) {
                    console.error(err)
                }
            }) ()
        }, [])

        return (
            <div className="flex flex-col justify-start h-1/3 mb-24 pb-96 font-nanum" id="orderstatus" ref={orderStatusRef}>
                <div className="font-bold mb-16 cursor-pointer text-2xl font-raleway" onClick={() => {setIsOpen(!isOpen)}}>
                    ORDER STATUS
                    <FontAwesomeIcon icon={isOpen ? faAngleDoubleUp : faAngleDoubleDown} className="ml-2 cursor-pointer"/>
                </div>
                
                {/* <div className={`${isOpen ? "" : "hidden"}`}>
                    <div className="flex flex-row justify-start">
                        {category.map((elem, index) => {
                            return (
                                <div 
                                    className={`mr-10 hover:underline transition-all duration-200 cursor-pointer 
                                    ${selected === elem.id ? "font-bold" : ""}`} 
                                    onClick={() => setSelected(elem.id)}
                                    key={index}>
                                    {`${elem.contents}(${stateCategories[elem.id]?.length || 0})`}
                                </div>
                            )
                        })}
                    </div>

                    <hr className=" h-[2.5px] bg-zinc-200 mt-8"/>

                    {category.map((elem, index) => {
                        return (
                            <div className={`w-full h-1/2 flex flex-col justify-start ${selected === elem.id ? "" : "hidden"}`} 
                                key={index} id={elem.id}>
                                <div className="flex flex-row justify-between mx-auto w-full py-3 bg-zinc-100 text-sm font-bold">
                                    <div className="flex flex-row w-2/5 justify-center ">상품정보</div>
                                    <div className="flex flex-row w-1/5 justify-center ">주문일자</div>
                                    <div className="flex flex-row w-1/5 justify-center ">주문금액</div>
                                </div>


                                <div className="w-full flex flex-col justify-start">
                                    {stateCategories[elem.id]?.map((item, index) => {
                                        return (
                                            <div className="flex flex-row justify-between w-full h-36 border-t" key={index}>
                                                <div className="flex flex-row w-2/5 justify-start">
                                                    <img className=" h-[80%] my-auto ml-5" alt={`${item.name}`} src={item.img} />
                                                    
                                                    <div className="my-auto ml-7">
                                                        <div className="font-bold">{item.name}</div>
                                                        <div className="text-sm text-gray-800">{item.option}</div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-center w-1/5 my-auto">
                                                    {item.orderDate}
                                                </div>

                                                <div className="flex justify-center w-1/5 my-auto">
                                                    {(item.orderPrice).toLocaleString('ko-KR')}
                                                </div>
                                            </div>
                                        )
                                    }) || 
                                    <div className="flex h-36 justify-center ">
                                        <div className="flex flex-col justify-center ">
                                            주문 내역이 없습니다.
                                        </div>
                                    </div>
                                    }
                                    
                                </div>

                                <hr className=" h-[2.5px] bg-zinc-200 "/>
                            </div>
                        )
                    })}
                </div> */}
            </div>
        )
    }


    return (
        <div className="flex flex-col justify-start w-[70%] 2xl:w-3/5 h-full pt-28 mx-auto">

            <h1 className="text-3xl font-raleway font-bold pb-8 ">MY PAGE</h1>
            
            <Profile />
            <Cart />
            <OrderStatus />

        </div>
    )
}

export default MyPage