import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { useFetch } from "../../hooks/useFetch";


export const SimpleCartTable = ({ cartData }) => {

    // const handleAllCheckedChange = (e) => {
    //     const checked = e.target.checked;
    //     setCartData(cartData.map(item => ({ ...item, checked })));
    // };
    
    // const handleItemCheckedChange = (index, checked) => {
    //     setCartData(cartData.map((item, i) =>
    //         i === index ? { ...item, checked } : item
    //     ));
    // };

    return (
        <div>
            <hr className={`h-[2.5px] bg-gray-800 mt-10`}/>

            <div className={`flex flex-row justify-between mx-auto w-full py-3 text-sm font-bold bg-zinc-100 `}>
                <div className="flex flex-row w-[45%] justify-center ">상품정보</div>
                <div className="flex flex-row w-[15%] justify-center ">수량</div>
                <div className="flex flex-row w-[20%] justify-center ">주문금액</div>
            </div>

            <div className="w-full flex flex-col justify-start">
                {cartData ? ( cartData.map((elem, index) => {

                    const { item: { item_name: name, image_urls: img}, quantity, options, total_price: totalPrice } = elem

                    return (
                        <div className={`flex flex-row justify-between w-full h-36 border-t border-gray-600 `} key={index}>

                            <div className="flex flex-row w-[45%] justify-start">
                                <img className=" h-[80%] my-auto ml-5" alt={`${name}`} src={img[0]} />
                                
                                <div className="my-auto ml-7">
                                    <div className="font-bold">{name}</div>
                                    <div className="text-sm text-gray-800">{options}</div>
                                </div>
                            </div>

                            <div className="flex justify-center w-[20%] my-auto">
                                {quantity}
                            </div>

                            <div className="flex justify-center w-[20%] my-auto">
                                ₩ {(totalPrice).toLocaleString('ko-KR')}
                            </div>
                            
                        </div>
                    )
                })) : ( 
                <div className="flex h-48 justify-center ">
                    <div className="flex flex-col justify-center ">
                        장바구니가 비어있습니다.
                    </div>
                </div>
                )}
                
            </div>

            <hr className={`h-[2.5px] bg-gray-800`}/>
        </div>
    )

}


const Cart = () => {

    const navigate = useNavigate()
    const { api } = useFetch()
    const cartRef = useRef(null);
    const [isOpen, setIsOpen] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [cartData, setCartData] = useState([])

    
    useEffect(() => {
        const hash = window.location.hash.substring(1);
        if (hash === 'cart' && cartRef.current) {
            cartRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [])

    useEffect(() => {
        (async () => {
            try {
                const { data, error } = await api({ action: "getCart" });

                if (error) {
                    console.error(error);
                } else {
                    setCartData(data.map(obj => ({
                        ...obj,
                        checked: true
                    })))
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false)
            }
        })();
    }, []);

    const CartTable = () => {

        const handleAllCheckedChange = (e) => {
            const checked = e.target.checked;
            setCartData(cartData.map(item => ({ ...item, checked })));
            console.log(cartData)
        };
        
        const handleItemCheckedChange = (index, checked) => {
            setCartData(cartData.map((item, i) =>
                i === index ? { ...item, checked } : item
            ));
            console.log(cartData)
        };
    
        return (
            <div className="font-nanum">
                <hr className={`h-[2.5px] bg-zinc-200 mt-10`}/>
    
                <div className={`flex flex-row justify-between mx-auto w-full py-3 text-sm font-bold bg-zinc-100`}>
                    <div className="flex flex-row w-[3%] justify-center ml-3">
                        <input type="checkbox" className="h-4 w-4 my-auto" 
                            checked={cartData.length > 0 && cartData.every(item => item.checked)}
                            onChange={handleAllCheckedChange}/>
                    </div>
                    <div className="flex flex-row w-[45%] justify-center ">상품정보</div>
                    <div className="flex flex-row w-[10%] justify-center ">수량</div>
                    <div className="flex flex-row w-[10%] justify-center ">주문금액</div>
                    <div className="flex flex-row w-[20%] justify-center ">주문관리</div>
                </div>
    
                <div className="w-full flex flex-col justify-start">
                    {cartData.length > 0 ? ( cartData.map((cart, index) => {

                        const { item, options, quantity, checked, total_price: totalPrice } = cart
                        const { item_name: itemName, image_urls: images } = item

                        return (
                            <div className={`flex flex-row justify-between w-full h-36 border-t `} key={index}>
    
                                <div className="flex flex-row w-[3%] justify-center ml-3">
                                    <input type="checkbox" className="h-4 w-4 my-auto"
                                        checked={cart.checked}
                                        onChange={() => handleItemCheckedChange(index, !checked)}
                                    />
                                </div>
                                
    
                                <div className="flex flex-row w-[45%] justify-start">
                                    <img className=" h-[80%] my-auto ml-5" alt={`${itemName}`} src={images[0]} />
                                    
                                    <div className="my-auto ml-7">
                                        <div className="font-bold">{itemName}</div>
                                        <div className="text-sm text-gray-800">{options}</div>
                                    </div>
                                </div>
    
                                <div className="flex justify-center w-[10%] my-auto">
                                    {quantity}
                                </div>
    
                                <div className="flex justify-center w-[10%] my-auto">
                                    ₩ {(totalPrice).toLocaleString('ko-KR')}
                                </div>
                                
                                <div className="flex justify-center w-[20%] my-auto">
                                    수량변경 / 옵션변경
                                </div>
                            </div>
                        )
                    })) : ( 
                    <div className="flex h-48 justify-center ">
                        <div className="flex flex-col justify-center ">
                            장바구니가 비어있습니다.
                        </div>
                    </div>
                    )}
                    
                </div>
    
                <hr className={`h-[2.5px] bg-zinc-300`}/>
            </div>
        )
    
    } 


    return (
        <div className={`flex flex-col justify-start h-1/3 mb-16 pb-28 `} id="cart" ref={cartRef}>

            <div className="font-bold font-raleway text-2xl">
                CART
                <FontAwesomeIcon icon={isOpen ? faAngleDoubleUp : faAngleDoubleDown} className="ml-2 cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)}/>
            </div>

            <div className={`${isOpen ? "" : "hidden"}`}>

                {isLoading ? null : (
                    <>
                    <CartTable />

                    <div className="flex flex-row justify-end mt-4 font-nanum">
                        <button className="mr-3 px-4 py-2 bg-gray-300 rounded"
                            onClick={async () => {
                                const filteredCartData = cartData.filter(item => !item.checked);
                                
                                const checkedCartIds = cartData
                                    .filter(cart => cart.checked)
                                    .map(cart => cart.cart_id);

                                await Promise.all(checkedCartIds.map(async id => {
                                    return api({
                                        action: "removeCart",
                                        params: {
                                            cartId: id
                                        },
                                        query: `/${id}`
                                    });
                                }));
                                setCartData(filteredCartData);
                                }
                            }
                        >
                            삭제
                        </button>
                        <button className=" px-4 py-2 bg-slate-700 text-white rounded cursor-pointer font-nanum"
                            onClick={() => {
                                const filteredCartData = cartData.filter(item => item.checked);
                                navigate('/order', {state: {
                                    _cartData: filteredCartData,
                                    orderType: "cart",
                                }})
                            }}
                        >
                            주문하기
                        </button>
                    </div>
                    </>
                )}
                
            </div>

            
        </div>
    )
}

export default Cart