import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useAuth } from "../../hooks/useAuth"

const Menu = ({ openModal }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const { logout } = useAuth()

    const { isLoggedIn } = useSelector(state => ({
        isLoggedIn: state.auth.isLoggedIn,
    }));

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const navItems = [
        { path: "about", label: "About" },
        { path: "collection", label: "Collection",
            // child: [
            //     { path: 'collection', label: 'Collection'}
            // ]
        },
        { path: "products", label: "Product" },
        { path: "service", label: "Community", 
            child: [
                { path: 'qna', label: 'Q&A' },
                { path: 'notice', label: 'Notice'},
            ]
        },
        { path: "contact", label: "Contact"},
        
    ]

    const elemStyle = " flex justify-start pl-10 h-14 2xl:h-16 cursor-pointer transition-all duration-300 hover:font-bold hover:bg-zinc-100 2xl:text-2xl items-center font-raleway"

    const Nav = ({ path, label, child }) => {
        const [isChildVisible, setIsChildVisible] = useState(false);

        return (
            <div 
                className="flex flex-col" 
                onMouseEnter={() => setIsChildVisible(true)} 
                onMouseLeave={() => setIsChildVisible(false)}
            >
                <Link to={`${path}`} onClick={toggleMenu} 
                    className={`${elemStyle} `} >
                    {label}
                </Link>
                {child && isChildVisible && (
                    <div className="flex flex-col animate-menubarIn overflow-hidden ">
                        {child.map((item) => (
                            <Link 
                                key={item.path} 
                                to={`${path}/${item.path}`} 
                                className="flex flex-col justify-center text-left pl-14 h-10 2xl:h-14 transition-all duration-300 
                                    hover:font-bold hover:bg-zinc-100 2xl:text-xl text-base font-raleway"
                                onClick={toggleMenu}
                            >
                                {item.label}
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    const MenuContents = () => {
        return (
            <div className='fixed top-0 bottom-0 right-0 left-0 flex flex-row justify-end
                text-lg leading-[4rem] z-30'>
                <div className={`absolute top-0 bottom-0 right-0 left-0 bg-gray-900 opacity-0
                    ${isMenuOpen ? "animate-fadeIn" : "animate-fadeOut"}`} />
                <div className={`pt-20 w-[17%] flex flex-col justify-start bg-white transform
                    ${isMenuOpen ? "animate-slideIn" : "animate-slideOut"}`}>

                    {isLoggedIn ? (
                        <div>
                            <div className={`${elemStyle}`}
                                onClick={() => {
                                    toggleMenu()
                                    navigate('/mypage')
                                }}>
                                Mypage
                            </div>

                            <div className={`${elemStyle}`}
                                onClick={() => {
                                    toggleMenu()
                                    logout()
                                    alert("로그아웃 되었습니다.")
                                    if (location.pathname === '/mypage') {
                                        navigate('/');
                                    }
                                }}>
                                Logout
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className={`${elemStyle}`} 
                                onClick={() => {
                                    toggleMenu()
                                    openModal()
                                }} >
                                Login
                            </div>
                            {/* <div className={`${elemStyle}`}
                                onClick={() => {
                                    toggleMenu()
                                    navigate('/signup')
                                }}>
                                Signup
                            </div> */}
                        </div>
                        
                    )}

                    {navItems.map((item, index) => (
                        <Nav key={index} path={item.path} label={item.label} child={item.child}/>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className='fixed top-2 right-2 cursor-pointer mx-10' onClick={toggleMenu}>
            <div className='relative transition-all duration-500 2xl:scale-[1.4] hover:scale-125 2xl:hover:scale-[1.5] z-40'>
                {/* <FontAwesomeIcon icon={faBars} className={`h-7`} /> */}
                <div className="relative w-6 h-8 mt-4 2xl:mt-6 cursor-pointer ">
                    <span
                        className={`block absolute top-1/2 left-0 w-full 2xl:h-[0.1rem] h-[0.2rem] bg-black rounded transform transition duration-300 
                        ${isMenuOpen ? 'rotate-45 -translate-y-1/2' : '-translate-y-2'}`}
                    ></span>
                    <span
                        className={`block absolute top-1/2 left-0 w-full 2xl:h-[0.1rem] h-[0.2rem] bg-black rounded transform transition duration-300 
                        ${isMenuOpen ? 'opacity-0' : ''}`}
                    ></span>
                    <span
                        className={`block absolute top-1/2 left-0 w-full 2xl:h-[0.1rem] h-[0.2rem] bg-black rounded transform transition duration-300 
                        ${isMenuOpen ? '-rotate-45 -translate-y-1/2' : 'translate-y-2'}`}
                    ></span>
                </div>
            </div>

            {isMenuOpen ? (<MenuContents />) : (null)}
        </div>
    )
}

export default Menu
