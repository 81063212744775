import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useOAuth from "../hooks/useOAuth";
import naver from "../asset/socialIcon/naver.png";
import kakao from "../asset/socialIcon/kakao.png";

const Login = () => {
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const { login } = useAuth();
    const { oAuthUrl } = useOAuth();

    const kakaoUrl = oAuthUrl("kakao");
    const naverUrl = oAuthUrl("naver");

    const handleLogin = (e) => {
        e.preventDefault();
        login({ id, password });
    };

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            handleLogin(e);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center" style={{ height: "calc(100vh - 160px)" }}>
            <form 
                className="w-full max-w-xs" 
                onSubmit={handleLogin}
            >
                <h1 className="text-center text-3xl font-bold mb-10">Logo</h1>

                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2" htmlFor="id">
                        ID
                    </label>
                    <input
                        type="text"
                        id="id"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        className="w-full px-3 py-2 border rounded bg-gray-50"
                        onKeyDown={onKeyPress}
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-sm font-medium mb-2" htmlFor="password">
                        PW
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2 border rounded bg-gray-50"
                        onKeyDown={onKeyPress}
                    />
                </div>

                <div className="flex justify-between text-sm mb-10">
                    <Link 
                        to="/signup" 
                        className="text-gray-800 hover:underline"
                    >
                        Register
                    </Link>
                    <Link 
                        to="/account" 
                        className="text-gray-800 hover:underline"
                    >
                        Forgot ID/PW
                    </Link>
                </div>

                <button
                    type="submit"
                    className="w-full bg-gray-900 text-white py-2 rounded mb-6"
                >
                    Login
                </button>

                <div className="flex justify-center space-x-4">
                    <img 
                        className="w-10 h-10 transition-all hover:scale-110 cursor-pointer" 
                        src={naver} 
                        alt="naver" 
                        onClick={() => window.location.href = naverUrl}
                    />
                    <img 
                        className="w-10 h-10 transition-all hover:scale-110 cursor-pointer" 
                        src={kakao} 
                        alt="kakao" 
                        onClick={() => window.location.href = kakaoUrl}
                    />
                </div>
            </form>
        </div>
    );
};

export default Login;
