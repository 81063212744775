import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import insta from "../../asset/insta.png"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Footer = () => {

    const [iconSize, setIconSize] = useState("xl")

    useEffect(()=> {

        if (parseInt(window.innerWidth) > 1535) {
          setIconSize("2xl")
        }  
  
      }, [])


    return (
        <div className=' '>

            <div className="cursor-pointer fixed 2xl:left-[110px] xl:left-[90px] lg:left-[70px] left-[50px] bottom-7 z-10"
                onClick={() => {window.open("https://www.instagram.com/hararam00/", "_blank")}}
                style={{ margin: 0, padding: 0 }}>
                <FontAwesomeIcon icon={faInstagram} size={iconSize} />
            </div>
            
            {/* <div className="cursor-pointer fixed 2xl:left-[110px] xl:left-[90px] lg:left-[70px] left-[50px] bottom-7 z-10"
                onClick={() => {window.open("https://www.instagram.com/hararam00/", "_blank")}}
                style={{ margin: 0, padding: 0 }}>
                <img src={insta} alt='instagram' className='w-10'/>
            </div> */}


            <Link to='/policy' className='fixed 2xl:right-[110px] xl:right-[90px] lg:right-[70px] right-[50px] bottom-7 z-10 text-sm 2xl:text-lg' >
                Policy
            </Link>

            <div className="w-full text-center my-10 px-6  text-xs lg:text-sm text-gray-500">
                <p>상호 : 뮤크섹슈얼 &nbsp; | &nbsp; 대표자 : 안태형</p>
                <p>사업자 등록번호 : 169-48-00621</p>
                <p>사업장 주소 : 서울시 관악구 미성5길 14 101호</p>
                <p>전화번호 : 010-2575-7041</p>
            </div>
        </div>
    )

}

export default Footer