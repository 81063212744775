import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useFetch } from "../hooks/useFetch";
import useOAuth from "../hooks/useOAuth"
import productImg from "../asset/1.jpeg";

const Main = () => {
    
    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const { oAuthLogin } = useOAuth()
    const { api } = useFetch()

    useEffect(() => {
        params.has("provider") &&
        (async () => {
            try {
                const { data, error } = await api({
                    action: `${params.get("provider")}OAuth`,
                    params: {
                        code: params.get("code"),
                        state: params.get("state")
                    }
                })
                if (!error) {
                    console.log(data)
                    const accessToken = data.access_token
                    const secretToken = data.secret_token
                    oAuthLogin({ accessToken, secretToken})
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) ()

    }, [location.search])

    return (
        <div>
            {/* 상단 메뉴바 */}
            <div className="w-full border-b-[1.5px] border-black py-2 fixed top-[60px] left-0 bg-white z-10">
                <div className="flex justify-around">
                    <div className="cursor-pointer" onClick={() => navigate('/about')}>About</div>
                    <div className="cursor-pointer" onClick={() => navigate('/collection')}>Collection</div>
                    <div className="cursor-pointer" onClick={() => navigate('/products')}>Product</div>
                    <div className="cursor-pointer" onClick={() => navigate('/service/notice')}>Notice</div>
                    <div className="cursor-pointer" onClick={() => navigate('/contact')}>Contact</div>
                </div>
            </div>

            {/* 메뉴바 아래 이미지 */}
            <div className="pt-[40px] min-h-screen"> {/* 메뉴바 높이만큼 패딩 추가 */}
                <img src={productImg} alt="product" className="w-full h-auto" />
            </div>
        </div>
    );
}

export default Main;
