import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import { v4 as uuidv4 } from 'uuid';
import { useFetch } from "../hooks/useFetch";

const OrderSummary = ({ items }) => (
    <div className="border-b py-6">
        {items.map((item, index) => (
            <div key={index} className="flex justify-between mb-4 pt-4 border-t">
                <div className="flex">
                    <img src={item.item.image_urls[0]} alt={item.item.item_name} className="w-24 h-24 object-cover rounded mr-4" />
                    <div>
                        <p className="font-semibold">{item.item.item_name}</p>
                        <p className="text-sm text-gray-600">옵션: {item.options}</p>
                        <p className="text-sm text-gray-600">{item.quantity}개 / ₩{item.total_price.toLocaleString()}원</p>
                    </div>
                </div>
            </div>
        ))}
        <div className="flex justify-between mt-4 pt-6 border-t">
            <p className="font-semibold">상품 합계</p>
            <p className="font-semibold">₩{items.reduce((sum, item) => sum + item.total_price, 0).toLocaleString()}원</p>
        </div>
    </div>
);

const UserInfo = ({ userOrderData, setUserOrderData, handleInputChange }) => {
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    
    const handlePostcodeSearch = () => {
        new window.daum.Postcode({
            oncomplete: function (data) {
                const roadAddr = data.roadAddress; // 도로명 주소
                const jibunAddr = data.jibunAddress; // 지번 주소
                const zonecode = data.zonecode; // 우편번호

                setUserOrderData((prevData) => ({
                    ...prevData,
                    address: roadAddr || jibunAddr, // 도로명 주소를 우선, 없으면 지번 주소
                    postCode: zonecode, // 우편번호 설정
                }));
            }
        }).open();
    };

    const InputField = ({ label, name, value, onChange, placeholder, readOnly = false }) => (
        <div>
            <label className="block text-sm font-medium">{label}</label>
            <input
                type="text"
                name={name}
                value={value || ""}  // 기본값을 빈 문자열로 설정
                onChange={onChange}
                placeholder={placeholder}
                readOnly={readOnly}
                className="w-full border rounded p-2 mt-1"
            />
        </div>
    );

    return (
        <div className="mt-8">
            <h2 className="font-semibold text-xl mb-10">주문 정보</h2>
            <div className="space-y-4">
                <InputField
                    label="이름"
                    name="recipientName"
                    value={userOrderData.recipientName || ""}  // 기본값 추가
                    onChange={handleInputChange}
                    placeholder="이름"
                />
                <InputField
                    label="연락처"
                    name="phoneNum"
                    value={userOrderData.phoneNum || ""}  // 기본값 추가
                    onChange={handleInputChange}
                    placeholder="연락처"
                />
                <div>
                    <label className="block text-sm font-medium">주소</label>
                    <div className="flex items-center space-x-2">
                        <input
                            type="text"
                            name="address"
                            value={userOrderData.address || ""}  // 기본값 추가
                            onChange={handleInputChange}
                            placeholder="주소"
                            readOnly
                            className="border rounded p-2 flex-grow"
                        />
                        <button
                            className="bg-[#2A2F5A] text-white rounded p-2 flex-shrink-0"
                            onClick={handlePostcodeSearch}
                        >
                            주소 검색
                        </button>
                    </div>
                </div>

                <InputField
                    label="우편 번호"
                    name="postCode"
                    value={userOrderData.postCode || ""}
                    onChange={handleInputChange}
                    placeholder="우편 번호"
                    readOnly
                />
                <InputField
                    label="상세 주소"
                    name="detailAddress"
                    value={userOrderData.detailAddress || ""}  // 기본값 추가
                    onChange={handleInputChange}
                    placeholder="상세 주소"
                />
                <InputField
                    label="배송 시 요청 사항"
                    name="requirements"
                    value={userOrderData.requirements || ""}  // 기본값 추가
                    onChange={handleInputChange}
                    placeholder="요청 사항"
                />
            </div>
        </div>
    );
};



const Order = () => {
    const location = useLocation();
    const { api } = useFetch();
    const { state } = location;
    const { _cartData, orderType } = state;

    const [cartData, setCartData] = useState(_cartData);
    const [userOrderData, setUserOrderData] = useState({
        item: [],
        address: "",
        detailAddress: "",
        phoneNum: "",
        requirements: "",
        recipientName: "",
        postCode: "",
        merchantId: "",
        totalPrice: "",
    });
    const [loading, setLoading] = useState(false);

    const paymentMethodWidgetRef = useRef(null); // Payment method widget ref
    const paymentWidgetRef = useRef(null); // Payment widget ref

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const initializeWidget = async () => {
            try {
                let data = null;
    
                if (orderType === 'cart') {
                    // 'cart' 주문 처리
                    const response = await api({
                        action: "initialCartOrder",
                        params: {
                            type: "cart",
                            cartId: cartData.map(item => item.cart_id),
                        },
                    });
                    data = response.data;
                } else if (orderType === 'item') {
                    // 'item' 주문 처리
                    const options = cartData.map(item => ({
                        item_id: item.item_id,
                        option: item.options,
                        quantity: item.quantity
                    }));
    
                    const response = await api({
                        action: "initialItemOrder",
                        params: {
                            type: "item",
                            options: options,
                        },
                    });
                    data = response.data;
                }
    
                if (!data) {
                    console.error('No data returned from API');
                    return;
                }
    
                // 주문 데이터 설정
                setUserOrderData({
                    item: data.ordering_item,
                    address: data.address,
                    detailAddress: data.detail_address,
                    phoneNum: data.phone_num,
                    requirements: data.requirements,
                    recipientName: data.recipientName,
                    postCode: data.post_code,
                    totalPrice: data.total_price,
                    merchantId: data.merchant_id,
                });
    
                // Toss Payments 위젯 초기화
                if (!paymentWidgetRef.current) {
                    const tossPayments = await loadTossPayments('test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm');
                    const customerKey = uuidv4();
    
                    const widgets = tossPayments.widgets({ customerKey });
    
                    widgets.setAmount({
                        currency: 'KRW',
                        value: data.total_price,
                    });
    
                    const paymentMethodWidget = await widgets.renderPaymentMethods({
                        selector: "#payment-widget",
                        variantKey: "DEFAULT",
                    });
    
                    await widgets.renderAgreement({
                        selector: "#agreement",
                        variantKey: "DEFAULT",
                    });
    
                    paymentMethodWidgetRef.current = paymentMethodWidget;
                    paymentWidgetRef.current = widgets;
                }
            } catch (err) {
                console.error('Error initializing widget:', err);
            }
        };
    
        initializeWidget();
    }, [cartData, orderType]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserOrderData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onPurchase = async () => {

        try {
            const selectedPaymentMethod = await paymentMethodWidgetRef.current.getSelectedPaymentMethod();

            console.log(userOrderData)

            const { data, error } = await api({
                action: "setOrder",
                params: {
                    itemInfo: userOrderData.item,
                    merchantId: userOrderData.merchantId,
                    recipientName: userOrderData.recipientName,
                    postCode: userOrderData.postCode,
                    address: userOrderData.address,
                    detailAddress: userOrderData.detailAddress,
                    phoneNum: userOrderData.phoneNum,
                    requirements: userOrderData.requirements,
                    paymentMethod: selectedPaymentMethod.code,
                    totalPrice: userOrderData.totalPrice,
                    couponId: ""
                }
            });

            if (!error) {
                const { res, err } = await api({
                    action: "setPayment",
                    params: {
                        paymentMethod: selectedPaymentMethod.code,
                        paymentName: `${userOrderData.item[0]} 외 ${userOrderData.item.length}건`,
                        merchantId: userOrderData.merchantId,
                        amount: userOrderData.totalPrice,
                    }
                })
                if (err) {
                    console.error(err)
                }
            } else {
                console.error(error);
            }
        } catch (err) {
            console.error(err);
        }

        try {
            if (paymentWidgetRef.current) {
                await paymentWidgetRef.current.requestPayment({
                    orderId: userOrderData.merchantId,
                    orderName: `${userOrderData.item[0]} 외 ${userOrderData.item.length}건`,
                    customerName: userOrderData.recipientName,
                    successUrl: `${window.location.origin}/order/success`,
                    failUrl: `${window.location.origin}/order/fail`,
                });
            }
        } catch (err) {
            console.error('결제 요청 중 오류가 발생했습니다:', err);
        }
    };

    const totalAmount = cartData.reduce((sum, item) => sum + item.total_price, 0);
    const discount = 0; // Implement discount logic here

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-8">주문 결제</h1>
            
            <OrderSummary items={cartData} />
            <UserInfo
                userOrderData={userOrderData}
                setUserOrderData={setUserOrderData}
                handleInputChange={handleInputChange}
            />

            <div>
                <hr className="my-10"/>
                <h2 className="font-semibold text-xl mb-14 ">결제 정보</h2>
                <div className="flex justify-between font-semibold ">
                    <span>총 결제 금액</span>
                    <span>₩{totalAmount.toLocaleString()}원</span>
                </div>
                <div className="flex justify-between mt-4 font-semibold">
                    <span>할인 금액</span>
                    <span>₩{discount.toLocaleString()}원</span>
                </div>
                <div className="flex justify-between mt-4 font-semibold">
                    <span>최종 결제 금액</span>
                    <span>₩{(totalAmount - discount).toLocaleString()}원</span>
                </div>
            </div>

            <div className="my-10">

                <div id="payment-widget" className="mb-4"></div>
                <div id="agreement" className="mb-4"></div>

                <button
                    onClick={onPurchase}
                    className={`w-full bg-[#2A2F5A] text-white py-3 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? '결제 중...' : `${(totalAmount - discount).toLocaleString()}원 결제하기`}
                </button>
            </div>
        </div>
    )
};

export default Order;
