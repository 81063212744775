const Contact = () => {
    const fontStyle = "mx-auto pb-4 text-lg 2xl:text-xl";

    return (
        <div className="w-full h-screen flex flex-col justify-center items-center">
            <div className={`${fontStyle} flex flex-row items-center`}>
                <div className="pr-1">Instagram:</div>
                <a href="https://www.instagram.com/FSO_official/"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer">
                    @FSO_official
                </a>
            </div>
            <div className={`${fontStyle}`}>Email: t___h@naver.com</div>
            <div className={`${fontStyle}`}>Tel. 010-2575-7041</div>
        </div>
    );
}

export default Contact;
