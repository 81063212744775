import React from "react";

const MyPage = () => {
    const userInfo = {
        name: "안태형",
        username: "fso1704",
        email: "fso@naver.com",
        address: "서울특별시 광진구 아차산로 51길 18 타워팰리스 1012",
    };

    const orderInfo = {
        orderNumber: "0123456788-0123456789",
        productName: "Gypsophilia T-shirt",
    };

    return (
        <div className="max-w-2xl mx-auto p-6 mt-10 mb-20">
            {/* <h1 className="text-center text-xl font-semibold mb-10">My page</h1> */}
            
            <div className="border-b pb-8 mb-8">
                <h2 className="font-semibold text-lg mb-4">회원정보</h2>
                <div className="text-center">
                    <p className="text-2xl font-semibold mb-1">{userInfo.name}</p>
                    <p className="text-sm text-gray-600 mb-1">{userInfo.username}</p>
                    <p className="text-sm text-gray-600 mb-1">{userInfo.email}</p>
                    <p className="text-sm text-gray-600 mb-4">{userInfo.address}</p>
                    <button className="bg-[#2A2F5A] text-white py-2 px-6 rounded">편집</button>
                </div>
            </div>

            <div className="border-b pb-8 mb-8">
                <h2 className="font-semibold text-lg mb-4">주문내역</h2>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between">
                        <span className="font-medium">주문번호</span>
                        <span>{orderInfo.orderNumber}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-medium">상품명</span>
                        <span>{orderInfo.productName}</span>
                    </div>
                </div>
                <div className="text-center mt-8">
                    <button className="bg-[#2A2F5A] text-white py-2 px-6 rounded">배송조회</button>
                </div>
            </div>

            <div className="pb-8 mb-8">
                <h2 className="font-semibold text-lg mb-4">쿠폰 내역</h2>
            </div>
        </div>
    );
};

export default MyPage;
