import { useState, useEffect } from 'react';
import { ReactComponent as TextLogo } from '../../asset/LOGO.svg';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [scrollY, setScrollY] = useState(0);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [navOffset, setNavOffset] = useState(0);
    const navigate = useNavigate();

    const SCROLL_THRESHOLD = 50; // 스크롤 민감도를 낮추기 위한 임계값

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const diff = currentScrollY - lastScrollY;

        if (Math.abs(diff) > SCROLL_THRESHOLD) { // 임계값을 넘어서는 경우에만 작동
            setNavOffset((prev) => {
                let newOffset = prev - diff;
                return Math.min(0, Math.max(newOffset, -60)); // 60px의 높이만큼 네비게이션 바를 숨김
            });

            setLastScrollY(currentScrollY);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [lastScrollY]);

    return (
        <div 
            style={{ transform: `translateY(${navOffset}px)` }} 
            className="w-full h-[60px] transition-transform duration-300 ease-out fixed top-0 z-10 border-b-[1.5px] border-gray-700 bg-white"
        >
            <header className="flex justify-center items-center text-center w-full h-full">
                <div className="flex items-center w-[20%] min-w-[120px]">
                    <TextLogo 
                        className="cursor-pointer" 
                        onClick={() => navigate('/')} 
                        style={{ width: '100%', height: 'auto' }} // SVG 크기 조정
                    />
                </div>
            </header>
        </div>
    );
};

export default Navbar;
