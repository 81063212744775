import { cookies } from "../utils/tokenStorage";

export const useFetch = () => {

    const api = async ({ action, params={}, query="" }) => {

        const url = 'https://api.fso.co.kr'
        
        // http://fso.co.kr/v1/docs
    
        const apiConfig = {
            //User
            signUp: {
                endpoint: "/v1/users/signup",
                method: "POST",
                body: JSON.stringify({
                    user_id: params.userId,
                    password: params.password,
                    name: params.name,
                    nickname: params.nickname,
                    email: params.email,
                    phone_num: params.phoneNum,
                    sns_confirm: params.agreeToSMS,
                    is_policy: params.agreeToEmailMarketing
                })
            },
            signIn: {
                endpoint: "/v1/users/signin",
                method: 'POST',
                body: JSON.stringify({
                    user_id: params.id,
                    password: params.password,
                })
            },
            isDuplicate: {
                endpoint: "/v1/users/check",
                method: "POST",
                body: JSON.stringify(
                    params.nickname ? { nickname: params.nickname } :
                    params.email ? { email: params.email } :
                    params.userId ? { user_id: params.userId } : {}
                )
            },
            getUserInfo: {
                endpoint: "/v1/users/me",
                method: "GET",
                authRequired: true,
            },
            refreshToken: {
                endpoint: "/v1/users/refresh",
                method: "POST",
                body: JSON.stringify({
                    refresh_token: cookies.get("refresh")
                })
            },
            setDelivery: {
                endpoint: "/v1/users/set-delivery",
                method: "POST",
                body: JSON.stringify({
                    delivery_name: params.deliveryName,
                    name: params.name,
                    email: params.email,
                    post_code: params.postCode,
                    address: params.address,
                    detail_address: params.detailAddress,
                    recipient_phone: params.phoneNum,
                    requirements: params.requirements,
                    is_base_delivery: params.isBaseDelivery
                }),
                authRequired: true,
            },
            findId: {
                endpoint: "/v1/users/find-id",
                method: "POST",
                body: JSON.stringify({
                    name: params.name,
                    phone_num: params.phoneNum,
                    email: params.email,
                })
            },
            findPassword: {
                endpoint: "/v1/users/find-password",
                method: "POST",
                body: JSON.stringify({
                    user_id: params.userId,
                    email: params.email
                })
            },
            resetPassword: {
                endpoint: "/v1/users/set_newpassword",
                method: "POST",
                body: JSON.stringify({
                    verification_token: params.token,
                    new_password: params.newPassword,
                    confirm_password: params.confirmPassword
                })
            },

            //Coupon
            getUserCoupon: {
                endpoint: "/v1/users/me/coupon",
                method: "GET",
                authRequired: true,
            },
            setUserCoupon: {
                endpoint: "/v1/users/me/add/coupon",
                method: "POST",
                body: JSON.stringify({
                    coupon_id: params.couponId
                }),
                authRequired: true,
            },
            getCoupon: {
                endpoint: "/v1/coupons/list",
                method: "GET",
                authRequired: true
            },
            
            //Social
            kakaoOAuth: {
                endpoint: "/v1/oauth/kakao/login",
                method: "POST",
                body: JSON.stringify({
                    code: params.code
                })
            },
            naverOAuth: {
                endpoint: "/v1/oauth/naver/login",
                method: "POST",
                body: JSON.stringify({
                    code: params.code,
                    state: params.state,
                })
            },
            
            //Q&A
            getQna: {
                endpoint: "/v1/qna",
                method: "GET"
            },
            getAuthQna: {
                endpoint: "/v1/qna/secret",
                method: "GET",
                authRequired: true
            },
            setQna: {
                endpoint: "/v1/qna/create",
                method: "POST",
                body: params,
                authRequired: true,
                isMultipart: true
            },
            getQnaComment: {
                endpoint: "/v1/comments/from-qna",
                method: "GET",
            },
            setQnaComment: {
                endpoint: "/v1/comments/from-qna/create",
                method: "POST",
                body: JSON.stringify({
                    base_qna: params.baseQna,
                    payload: params.payload
                }),
                authRequired: true,
            },
            updateQnaComment: {
                endpoint: "/v1/comments",
                method: "PUT",
                body: JSON.stringify({
                    payload: params.payload
                }),
                authRequired: true,
            },
            removeQnaComment: {
                endpoint: "/v1/comments/delete",
                method: "DELETE",
                authRequired: true,
            },
            getNotice: {
                endpoint: "/v1/notice",
                method: "GET",
            },
            
            //Item
            getItems: {
                endpoint: "/v1/items",
                method: "GET",
            },
            getItem: {
                endpoint: "/v1/items/",
                method: "GET"
            },
            
            //Cart
            getCart: {
                endpoint: "/v1/cart",
                method: "GET",
                authRequired: true,
            },
            setCart: {
                endpoint: "/v1/cart/create",
                method: "POST",
                body: JSON.stringify({
                    item_id: params.itemId,
                    options: params.options,
                }),
                authRequired: true,
            },
            removeCart: {
                endpoint: "/v1/cart/delete",
                method: "DELETE",
                body: JSON.stringify({
                    cart_id: params.cartId,
                }),
                authRequired: true,
            },
            
            //Order
            getOrderInfo: {
                endpoint: "/v1/orders",
                method: "GET",
                authRequired: true,
            },
            initialCartOrder: {
                endpoint: "/v1/orders/start",
                method: "POST",
                body: JSON.stringify({
                    type: params.type,
                    cart_id: params.cartId
                }),
                authRequired: true,
            },
            initialItemOrder: {
                endpoint: "/v1/orders/start",
                method: "POST",
                body: JSON.stringify({
                    type: params.type,
                    options: params.options,
                }),
                authRequired: true,
            },
            setOrder: {
                endpoint: "/v1/orders/done",
                method: "POST",
                body: JSON.stringify({
                    item_info: params.itemInfo,
                    merchant_id: params.merchantId,
                    recipient_name: params.recipientName,
                    post_code: params.postCode,
                    address: params.address,
                    detail_address: params.detailAddress,
                    phone_num: params.phoneNum,
                    requirements: params.requirements,
                    payment_method: params.paymentMethod,
                    total_price: params.totalPrice,
                    apply_coupon_id: params.couponId
                }),
                authRequired: true,
            },
            setPayment: {
                endpoint: "/v1/payment/toss",
                method: "POST",
                body: JSON.stringify({
                    payment_method: params.paymentMethod,
                    payment_name: params.paymentName,
                    merchant_id: params.merchantId,
                    amount: params.amount
                }),
                authRequired: true,
            },
            paymentSuccess: {
                endpoint: "/v1/payment/success",
                method: "GET",
                body: JSON.stringify({
                    amount: params.amount,
                    paymentKey: params.paymentKey,
                    paymentType: params.paymentType,
                    orderId: params.orderId
                })
            }
            
        }

        try {
            const config = apiConfig[action];
            if (!config) {
                throw new Error('Invalid action');
            }
            
            const requestOptions = {
                method: config.method,
                headers: {}
            };
            if (config.method === "POST" || config.method === "PUT") {
                requestOptions.body = config.body;
            }
            if (config.authRequired) {
                requestOptions.headers["Authorization"] = `Bearer ${cookies.get("access")}`;
            }
            if (!config.isMultipart) {
                requestOptions.headers['Content-Type'] = 'application/json';
            }

            console.log(requestOptions, config)

            const response = await fetch(`${url}${config.endpoint}${query}`, requestOptions);

            const responseData = await response.json()

            if (!response.ok) {
                return { data: null, error: await responseData.detail}
            }

            return { data: await responseData, error: null }
        }
        catch (error) {
            console.error(error)
            return { data: null, error: error.message }
        }
    }

    return { api }
};