import { Route, Routes } from "react-router-dom"

import Layout from "./mobile/Layout/Layout"
import Main from './mobile/Main'
import Products from './mobile/Products'
import Product from './mobile/Product'
import QnaList from './pages/QnaList'
import QnaDetail from './pages/QnaDetail'
import QnaCreate from './pages/QnaCreate'
import MyPage from './mobile/MyPage'
import SignUp from './mobile/SignUp'
import Cart from "./mobile/Cart"
import Order from "./mobile/Order"
import { Success, Fail } from "./components/common/OrderResponse"
import Login from "./mobile/Login"


import Policy from "./pages/Policy"
import Notice from "./mobile/Notice"
import About from "./pages/About"
import Collection from "./pages/Collection"
import Contact from "./pages/Contact"

const MobileRoutes = () => {

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="/" element={<Main />}/>
                <Route path="/mypage" element={<MyPage />} />
                <Route path="/signup" element={<SignUp />} />
                {/* <Route path="/account" element={<Forgot />} /> */}
                <Route path="/login" element={<Login />} />

                <Route path="/service" element={<QnaList />} />
                <Route path="/service/qna" element={<QnaList />} />
                <Route path="/service/contents/:id" element={<QnaDetail />} />
                <Route path="/service/write" element={<QnaCreate />} />
                <Route path="/service/notice" element={<Notice />} />

                <Route path="/products" element={<Products />}/>
                <Route path="/product/:id" element={<Product />} />

                <Route path="/cart" element={<Cart />} />
                <Route path="/order" element={<Order />} />
                <Route path="/order/success" element={<Success />} />
                <Route path="/order/fail" element={<Fail />} />

                <Route path="/policy" element={<Policy />} />
                <Route path="/collection" element={<Collection />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
            </Route>
        </Routes>
    )
}

export default MobileRoutes